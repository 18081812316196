import React from 'react';

import Home from "./screens/home/Home";
import Resume from "./screens/resume/Resume";
import Portfolio from "./screens/portfolio/Portfolio";

import "./Screen.scss"

function Screen(props) {
    let pageScreens = {
        home: <Home />,
        resume: <Resume />,
        portfolio: <Portfolio />,
    }

    let pageScreen = pageScreens[props.page];

    return (
        <div className="screen">
            { pageScreen }
        </div>
    );
}

export default Screen;