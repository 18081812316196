import React from 'react';
import classNames from 'classnames';

import "./AboutSection.scss";

function AboutSection(props) {
    let { section, showBody, onClick } = props;
    let { title, body } = section;
    let sectionClasses = classNames("about-section", showBody ? "open" : "closed");

    return (
        <div className={sectionClasses}>
            <div className="title-container clickable" onClick={onClick}>
                {
                    showBody ?
                    <img src="/images/angle-down.svg" alt="About section open" />
                    : <img src="/images/angle-right.svg" alt="About section closed" />
                }
                <div className="title">
                    {title}
                </div>
            </div>
            { showBody && (
                <p className="body">
                    {body}
                </p>
            )}
        </div>
    );
}

export default AboutSection;