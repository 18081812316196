import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Screen from './Screen';
import NavButton from "./NavButton";

import './App.scss';

class App extends React.Component {
  state = {
    page: "home",
    goRight: true,
  }
  
  render() {
    let { page, goRight } = this.state;

    return (
      <div className="page-container">
        <div className="navbar">
          <NavButton label="Home" onClick={() => this.changePage("home")} selected={page === "home"}/>
          <NavButton label="Resume" onClick={() => this.changePage("resume")} selected={page === "resume"}/>
          <NavButton label="Portfolio" onClick={() => this.changePage("portfolio")} selected={page === "portfolio"}/>
        </div>
        
        <TransitionGroup className="screen-container">
          <CSSTransition 
            key={page}
            classNames={goRight ? "screen-right" : "screen-left"}
            timeout={1000}
          >
            <Screen page={page} key={page} />
          </CSSTransition>
        </TransitionGroup>
      </div>
    )

  }

  changePage(newPage) {
    let currentPage = this.state.page;
    let goRight = (
      (currentPage === "home" && newPage === "resume") ||
      (currentPage === "resume" && newPage === "portfolio") ||
      (currentPage === "portfolio" && newPage === "home")
    )

    // setState twice in order to force a refresh - classNames
    // must be changed for CSSTransition before changing the
    // page for transition to go in the correct direction
    this.setState(
      { goRight },
      () => this.setState({ page: newPage })
    );
  }
}

export default App;
