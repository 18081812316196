import React from 'react';
import classNames from 'classnames';

import Github from "../../svg-images/Github";
import ExternalLink from "../../svg-images/ExternalLink";

import "./PortfolioItem.scss";

function PortfolioItem(props) {
    let { purpose, tech, story, image, imageAltText, githubLink, projectLink } = props.item;
    let firstColClasses = classNames("column", image ? "left-container" : "single-col");

    return (
        <div className="portfolio-item">
            <div className="body-container shrink-changes-flex-direction">
                <div className={firstColClasses}>
                    <div className="section tech">
                        <label>Tech Used:</label>
                        <p>{tech}</p>
                    </div>
                    <div className="section purpose">
                        <label>Purpose:</label>
                        <p>{purpose}</p>
                    </div>
                    <div className="section story">
                        <label>Story:</label>
                        <p>{story}</p>
                    </div>
                </div>
                {image &&
                    (
                        <div className="column right-container">
                            <img src={"/images/" + image } alt={imageAltText}/>
                        </div>
                    )
                }
            </div>
            <div className="links-container">
                {
                    projectLink &&
                    <ExternalLink link={projectLink} />
                }
                {
                    githubLink &&
                    <Github link={githubLink} />
                }
            </div>
        </div>
    );
}

export default PortfolioItem;