import React from "react";

import AboutSection from "./AboutSection";
import sections from "./about-sections.json";

import "./About.scss";

class About extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            openSection: undefined
        }

        this.onTitleClick = this.onTitleClick.bind(this);
    }

    render() {
        let sectionsRendered = [];
        sections.forEach((element, index) => {
            let showBody = this.state.openSection === index;
            sectionsRendered.push(
                <AboutSection
                    key={index}
                    section={element}
                    showBody={showBody}
                    onClick={() => this.onTitleClick(index)}
                />
            )
        });


        return (
            <div className="about-container">
                {sectionsRendered}
            </div>
        );
    }

    onTitleClick(index) {
        // if currently open, close it
        this.setState({
            openSection: (this.state.openSection === index ? undefined : index)
        });
    }
}

export default About;