import React from 'react';

import About from './About';
import Github from "../../svg-images/Github";
import Linkedin from "../../svg-images/Linkedin";

import "./Home.scss";

function Home(props) {
    return (
        <div className="top-container shrink-changes-flex-direction">
            <div className="sidebar">
                <div className="sidebar-items-container">
                    <img className="picture" src="images/me.jpg" alt="Lucas Kearns" />
                    <div className="title">Lucas Kearns</div>
                    <div className="links-container">
                        <Linkedin link="https://www.linkedin.com/in/lpkearns/" />
                        <Github link="https://github.com/lpkearns"/>
                    </div>
                </div>
            </div>
            <About />
        </div>
    )
}

export default Home;