import React from 'react';
import classNames from 'classnames';

import "./Tab.scss";

function Tab(props) {
    let { title, first, selected, onClick } = props;
    let classes = classNames(
            "tab",
            first && "first",
            selected && "selected"
        );

    return (
        <div
            className={classes}
            onClick={onClick}
        >
            {title}
        </div>
    );
}

export default Tab;