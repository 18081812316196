import React from "react";

import "./CompanyButton.scss";

function CompanyButton(props) {
    let { company, onClick } = props;
    let { companyName, jobTitle, logo, logoAltText } = company;

    return (
        <div className="company-button clickable" onClick={onClick}>
            <img className="company-logo" src={"/images/" + logo} alt={logoAltText} />
            <div className="title">{companyName}</div>
            <div className="job-title">{jobTitle}</div>
        </div>
    )
}

export default CompanyButton;