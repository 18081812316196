import React from 'react';
import classNames from 'classnames';

import './NavButton.scss';

function NavButton(props) {
    let { label, selected, onClick} = props;
    let classes = classNames("nav-button", selected && "selected");

    return (
        <div className={classes} onClick={onClick}>
            <label>
                {label}
            </label>
        </div>
    )
}

export default NavButton;