import React from 'react';

import Tab from './Tab';
import PortfolioItem from './PortfolioItem';
import portfolioItems from './portfolio-items.json'
import './Portfolio.scss';

class Portfolio extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            selected: 0
        }

        this.onTabClick = this.onTabClick.bind(this);
    }

    render() {
        let selected = this.state.selected;

        const tabs = [];
        portfolioItems.forEach((item, index) => {
            tabs.push(
                <Tab
                    key={index}
                    title={item.title}
                    first={index===0}
                    selected={index===selected}
                    onClick={() => this.onTabClick(index)}
                />
            )
        });

        return (
            <div className="portfolio">
                <div className="tabs-container">
                    {tabs}
                </div>
                <PortfolioItem item={portfolioItems[selected]} />
            </div>
        )
    }

    onTabClick(tabIndex) {
        this.setState({
            selected: tabIndex
        });
    }
}

export default Portfolio;