import React from 'react';

import CompanyButton from "./CompanyButton";
import Company from "./Company";
import companies from './companies.json';

import './Resume.scss';

class Resume extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            selected: undefined
        }

        this.onCompanyClick = this.onCompanyClick.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.companySelected = this.companySelected.bind(this);
    }

    render() {
        let { selected } = this.state;

        return (
            <div className="resume">
                {this.companySelected() &&
                    <Company company={companies[selected]} onCloseClick={() => this.onCloseClick}/>}
                {!this.companySelected() &&
                    <div className="company-buttons shrink-changes-flex-direction">
                        {companies.map((company, index) => (
                            <CompanyButton
                                key={index}
                                company={company}
                                onClick={() => this.onCompanyClick(index)} />
                        ))}
                    </div>
                }
            </div>
        )
    }

    onCompanyClick(index) {
        this.setState({ selected: index });
    }

    onCloseClick() {
        this.setState({ selected: undefined });
    }

    companySelected() {
        return this.state.selected !== undefined;
    }
}

export default Resume;