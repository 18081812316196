import React from "react";

import CloseSymbol from "../../svg-images/CloseSymbol";
import Linkedin from "../../svg-images/Linkedin";
import ExternalLink from "../../svg-images/ExternalLink";

import "./Company.scss";

function Company(props) {

    let {
        companyName,
        sections,
        logo,
        logoAltText,
        companySite,
        companyLinkedin
    } = props.company;

    return (
        <div className="company">
            <CloseSymbol onClick={props.onCloseClick}/>
            <div className="image-container">
                <img className="company-logo" src={"/images/" + logo} alt={logoAltText} />
                <div className="title">{companyName}</div>
            </div>
            <div className="details-container">
                {
                    sections.map((section, index) => {
                        section.index = index;
                        return renderSection(section)
                    })
                }
            </div>
            <div className="links-container">
                <ExternalLink link={companySite} />
                <Linkedin link={companyLinkedin} />
            </div>
        </div>
    )
}

function renderSection(section) {
    return section.body ? sectionWithoutPoints(section) : sectionWithPoints(section);
}

function sectionWithoutPoints(section) {
    let { label, body } = section;
    return (
        <div key={section.index} className="section">
            <label>{label}:</label>
            <p>{body}</p>
        </div>
    )
}

function sectionWithPoints(section) {
    let { label, intro, points } = section;
    let listItems = points.map((point, index) => {
        return (
            <li key={index}>{point}</li>
         )
    });

    return (
        <div key={section.index} className={"section section-with-points"}>
            <label>{label}:</label>
            <div className="section-body-container">
                <p>{intro}</p>
                <ul>
                    {listItems}
                </ul>
            </div>
        </div>
    );
}

export default Company;